<template>
    <section id="schema" class="section_container">
      <v-container>
        <default-home-section-title :title="titleSection" />
        <v-card-text>
            <p class="text-h3 font-weight-medium" style="color: black" align="center">
                Борьба с коррупцией - это борьба за будущее нашей страны. 
            </p>
        </v-card-text>
        <v-img width="80%" class="mx-auto" :src="imgSchema1"></v-img>
      </v-container>
    </section>
  </template>
  <script>
  export default {
    name: "HomeAntikorSection",
    components: {
      DefaultHomeSectionTitle: () => import("@/views/Home/HomeSectionTitle.vue"),
    },
    data() {
      return {
        titleSection: "Памятка по противодействию коррупции",
        // imgSchema: require("@/assets/img/public-schema-img-01.jpeg"),
        // imgSchema: require("@/assets/img/public-schema-img-02.png"),
        imgSchema1: require("@/assets/img/Antikor_memo1.jpg"), //! По распоряжению Жанарыса от 31.03.2022
      };
    },
  };
  </script>
  <style lang="scss" scoped>
  .section_container {
    background-color: #fff;
    width: 70%;
    margin: 0 auto;
  }
  .section_link {
    color: white;
    text-decoration: none;
  }
  .section_link:hover {
    color: black;
  }
  </style>
  