import { render, staticRenderFns } from "./HomeAntikorSection.vue?vue&type=template&id=e031d3f2&scoped=true&"
import script from "./HomeAntikorSection.vue?vue&type=script&lang=js&"
export * from "./HomeAntikorSection.vue?vue&type=script&lang=js&"
import style0 from "./HomeAntikorSection.vue?vue&type=style&index=0&id=e031d3f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e031d3f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCardText,VContainer,VImg})
